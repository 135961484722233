import { Me, SignupResponeMeta } from './authenticationTypes'
import {
    RequestEmailTotpLoginLinkPayload,
    RequestPasswordResetPayload,
    SignupRequestPayloadData,
} from '@api/auth/authenticationTypes'
import { RequestLoginMethodPayload, RequestLoginMethodResponse } from '@api/auth/authenticationTypes'
import { AUTH_CALLS } from '@api/auth/authCalls'
import { Page, TrackingTags } from '@utils/urls'
import { getCookie, deleteCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { useMemo, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { EnterMutationOptions, useEnterApi, useEnterMutation, useEnterQuery } from 'lib/api/apiContext'
import { EnterResponse } from 'lib/api/apiFactory'
import {
    IMPERSONATION_ADMIN_TOKEN,
    IMPERSONATION_ADMIN_TOKEN_EXPIRY,
    useEnterAuthConfig,
    getImpersonationAdminToken,
    getUnexpiredToken,
} from 'lib/utils/authentication'
import { getValidTrackingParams } from '@common/helpers/form/formHelpers'
import { sendGTMEvent } from 'lib/utils/googleTagManager'
import { GTMEvent, GTMValue } from 'lib/types/GTMTypes'
import { signOut } from 'lib/utils/firebaseAuthentication'

export const useRequestLoginMethod = () => {
    const enterApi = useEnterApi()

    const { getKey, call } = AUTH_CALLS.mutations.requestLoginMethod

    return useEnterMutation<RequestLoginMethodResponse, RequestLoginMethodPayload>(
        getKey(),

        (payload) => call(enterApi, payload),
        {
            onSuccess: () => {
                sendGTMEvent(GTMEvent.USER_AUTH, GTMValue.LOGIN)
            },
        }
    )
}

export const useMe = (isIgnoreError = false) => {
    const qc = useQueryClient()
    const router = useRouter()
    const enterApi = useEnterApi()

    const urlAdminToken = getUnexpiredToken(
        router.query[IMPERSONATION_ADMIN_TOKEN] as string,
        router.query[IMPERSONATION_ADMIN_TOKEN_EXPIRY] as string
    )
    const storageAdminToken = getImpersonationAdminToken()
    const adminToken = urlAdminToken || storageAdminToken

    const { authCookieName } = useEnterAuthConfig()
    const isTokenPresent = Boolean(getCookie(authCookieName)) || Boolean(adminToken)

    const query = AUTH_CALLS.queries
    const [controller] = useState(new AbortController())

    useEffect(() => {
        if (!isIgnoreError && !isTokenPresent && !router.asPath.startsWith(Page.LOGIN)) {
            controller.abort()
            window.location.href = window.location.origin + Page.LOGIN
        }
    }, [isIgnoreError, router, isTokenPresent, controller])

    const queryResponse = useEnterQuery(
        adminToken ? query.adminMe.getKey() : query.me.getKey(),
        adminToken
            ? () =>
                  query.adminMe.call(enterApi, {
                      signal: controller.signal,
                  })
            : () =>
                  query.me.call(enterApi, {
                      signal: controller.signal,
                  }),
        {
            enabled: Boolean(router.isReady),
        }
    )

    useEffect(() => {
        if (!queryResponse.isError || isIgnoreError) return

        window?.sessionStorage?.removeItem(IMPERSONATION_ADMIN_TOKEN)
        deleteCookie(IMPERSONATION_ADMIN_TOKEN)
        signOut().finally(() => {
            qc.clear()
            window.location.href = window.location.origin + Page.LOGIN
        })
    }, [isIgnoreError, queryResponse.isError, router, qc, authCookieName])

    return queryResponse
}

export const useSignup = () => {
    const enterApi = useEnterApi()
    const router = useRouter()
    const formUuid = (router.query.formUuid as string) || ''
    const trackingQueryParams = useMemo(
        () => getValidTrackingParams(router.query, Object.values(TrackingTags)),
        [router]
    )
    const { getKey, call } = AUTH_CALLS.mutations.signup

    return useEnterMutation<EnterResponse<Me, SignupResponeMeta>, SignupRequestPayloadData>(
        getKey(),
        (payload) => call(enterApi, payload, formUuid, trackingQueryParams),
        {
            onSuccess: () => {
                sendGTMEvent(GTMEvent.USER_AUTH, GTMValue.SIGN_UP)
            },
        }
    )
}

export const useRequestPasswordReset = () => {
    const enterApi = useEnterApi()

    const {
        mutations: { requestPasswordReset },
    } = AUTH_CALLS

    return useEnterMutation<undefined, RequestPasswordResetPayload>(requestPasswordReset.getKey(), (payload) =>
        requestPasswordReset.call(enterApi, payload)
    )
}

export const useResendEmailVerificationLink = (options?: EnterMutationOptions<undefined, void>) => {
    const enterApi = useEnterApi()

    const {
        mutations: { resendEmailVerificationLink },
    } = AUTH_CALLS

    return useEnterMutation<undefined, void>(
        resendEmailVerificationLink.getKey(),
        () => resendEmailVerificationLink.call(enterApi),
        options
    )
}

export function useRequestMagicLoginLink() {
    const enterApi = useEnterApi()

    const {
        mutations: { requestEmailTotpLoginLink: requestMagicLoginLink },
    } = AUTH_CALLS

    return useEnterMutation<undefined, RequestEmailTotpLoginLinkPayload>(
        requestMagicLoginLink.getKey(),
        (payload) => requestMagicLoginLink.call(enterApi, payload),
        {
            onSuccess: () => {
                sendGTMEvent(GTMEvent.USER_AUTH, GTMValue.LOGIN)
            },
        }
    )
}

export const useLogout = () => {
    const qc = useQueryClient()
    const enterApi = useEnterApi();

    return () => {
        sendGTMEvent(GTMEvent.USER_AUTH, GTMValue.LOGOUT)
        window?.sessionStorage?.removeItem(IMPERSONATION_ADMIN_TOKEN)
        deleteCookie(IMPERSONATION_ADMIN_TOKEN)

        // call log out webhook
        // This is mostly used to make the flow with wiremock work
        // but obviously this can be used to trigger any backend logic simultaneously
        const { 
            mutations: { logout },
        } = AUTH_CALLS
        logout.call(enterApi)

        signOut().finally(() => {
            enterApi
            qc.clear()
            window.location.href = window.location.origin + Page.LOGIN
        })
    }
}
