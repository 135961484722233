import { useGetBuildingByUuid, useGetAllBuildings } from '@api/buildings/buildingsHooks'
import { getBuildingDisplayAdress } from '@common/helpers/building/getBuildingDisplayAdress'
import { useTransferAdminToken } from '@common/hooks/useTransferAdminToken'
import { UseQueryResult } from '@tanstack/react-query'
import { sendToast } from 'lib/components/toast'
import { GetBuildings } from 'lib/types/buildings/buildingsTypes'
import { ChildrenProps } from 'lib/types/propTypes'
import { EnterError } from 'lib/api/apiFactory'
import { parseRouterParam } from 'lib/utils/helpers'
import { useRouter } from 'next/router'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getImpersonationAdminToken } from 'lib/utils/authentication'

export type BuildingContextProviderProps = ChildrenProps
type BuildingContext = {
    queryResult: UseQueryResult<GetBuildings, EnterError>
    handleBuildingChange: (newBuildingUuid: string) => void
    lastFetchedBuilding: UseQueryResult<GetBuildings, EnterError> | undefined
    buildingData: GetBuildings | undefined
    buildingsList: GetBuildings[] | undefined
    isInitialFetch: boolean
    isBuildingLoading: boolean
}

export const BuildingContext = createContext<BuildingContext | null>(null)

export const BuildingContextProvider = ({ children }: BuildingContextProviderProps) => {
    const [globalBuildingUuid, setGlobalBuildingUuid] = useState<string>()
    const [lastFetchedBuilding, setLastFetchedBuilding] = useState<UseQueryResult<GetBuildings, EnterError>>()

    const router = useRouter()
    const urlBuildingUuid = parseRouterParam(router.query.buildingUuid)

    // We always fetch the building that is globably set.
    const shouldFetchBuildingByUuid = useMemo(() => Boolean(urlBuildingUuid), [urlBuildingUuid])

    useTransferAdminToken()

    // Fetch the global building by uuid.
    const buildingQueryResult = useGetBuildingByUuid({
        buildingUuid: globalBuildingUuid || '',
        enabled: shouldFetchBuildingByUuid,
    })

    // The all buildings should be fetched when:
    // - getting the building by uuid failed
    // - there is no global building uuid and no url building uuid (this would be the case if you need access to the building on a page which doesn't have a building uuid in the buildingUuid in the url)
    const shouldFetchAllBuildings = useMemo(
        () => Boolean(router.isReady && (buildingQueryResult.error || (!globalBuildingUuid && !urlBuildingUuid))),
        [buildingQueryResult, globalBuildingUuid, router, urlBuildingUuid]
    )

    const isAdmin = Boolean(getImpersonationAdminToken())

    // // Fetch the all buildings.
    const { data: allBuildings } = useGetAllBuildings({
        enabled: !isAdmin && shouldFetchAllBuildings,
    })

    const isInitialFetch = useMemo(() => Boolean(!lastFetchedBuilding), [lastFetchedBuilding])

    const isBuildingLoading = useMemo(() => buildingQueryResult.isLoading, [buildingQueryResult.isLoading])

    const handleBuildingChange = useCallback(
        (newBuildingUuid: string) => {
            setGlobalBuildingUuid(newBuildingUuid)

            const shouldRedirect = Boolean(urlBuildingUuid && urlBuildingUuid !== newBuildingUuid)

            if (shouldRedirect) {
                router.replace(
                    {
                        query: {
                            ...router.query,
                            buildingUuid: newBuildingUuid,
                        },
                    },
                    {},
                    { scroll: false, shallow: true }
                )
            }
        },
        [router, urlBuildingUuid]
    )

    const handleLastFetchedBuildingChange = useCallback(
        (newLastFetchedBuildingQueryResult: UseQueryResult<GetBuildings, EnterError> | undefined) => {
            setLastFetchedBuilding(newLastFetchedBuildingQueryResult)

            if (!newLastFetchedBuildingQueryResult || isInitialFetch) return

            const buildingName = newLastFetchedBuildingQueryResult.data
                ? getBuildingDisplayAdress(newLastFetchedBuildingQueryResult.data)
                : ''

            if (!buildingName) return

            sendToast({ message: `Sie befinden sich nun im Gebäude ${buildingName}`, type: 'success' })
        },
        [isInitialFetch]
    )

    useEffect(() => {
        if (shouldFetchAllBuildings) {
            const firstBuilding = allBuildings && allBuildings[0]
            if (!firstBuilding) return

            return handleBuildingChange(firstBuilding.uuid)
        }
        if (!globalBuildingUuid && urlBuildingUuid) {
            return handleBuildingChange(urlBuildingUuid)
        }
    }, [allBuildings, globalBuildingUuid, handleBuildingChange, shouldFetchAllBuildings, urlBuildingUuid])

    useEffect(() => {
        buildingQueryResult.refetch()
    }, [router.pathname])

    useEffect(() => {
        // If no building has been fetched yet or the building is loading, there is also no lastFetchedBuilding to be updated.
        if (!buildingQueryResult.data || buildingQueryResult.isLoading) return

        // If the building fetch failed, we reset the lastFetchedBuilding.
        if (buildingQueryResult.error) {
            handleLastFetchedBuildingChange(undefined)
        }

        // If:
        // 1. A building has been fetched, but we haven't yet saved it as the lastFetchedBuilding, or
        // 2. The current building doesn't equal the lastFetchedBuilding, we update the lastFetchedBuilding it.
        if (
            !lastFetchedBuilding ||
            JSON.stringify(lastFetchedBuilding.data) !== JSON.stringify(buildingQueryResult.data)
        ) {
            handleLastFetchedBuildingChange(buildingQueryResult)
        }
    }, [buildingQueryResult, handleBuildingChange, handleLastFetchedBuildingChange, lastFetchedBuilding])

    return (
        <BuildingContext.Provider
            value={{
                queryResult: buildingQueryResult,
                handleBuildingChange,
                lastFetchedBuilding,
                buildingData: buildingQueryResult.data || (allBuildings && allBuildings[0]),
                buildingsList: allBuildings,
                isInitialFetch,
                isBuildingLoading,
            }}
        >
            {children}
        </BuildingContext.Provider>
    )
}

export const useBuildingContext = () => {
    const context = useContext(BuildingContext)

    if (context === null) {
        throw new Error('useBuildingContext must be used within a BuildingContextProvider')
    }

    return context
}
