import {
    GetBuildingMeasures,
    GetBuildingMeasuresFaq,
    GetBuildingCategories,
    GetBuildingArgs,
} from '@api/buildings/buildingsTypes'
import { BuildingsInclude, SurveyName } from 'lib/types/buildings/buildingsTypes'
import { BuildingMeasuresValues, GetBuildings } from 'lib/types/buildings/buildingsTypes'
import { createApiUri, EnterApi } from 'lib/api/apiFactory'

export interface SubmitBuildingPayload {
    [key: string]: unknown
}

export enum BuildingCallsBasicKey {
    GET_ALL_BUILDINGS = 'getAllBuildings',
    GET_BUILDING = 'getBuilding',
    GET_BUILDING_MEASURES = 'getBuildingMeasures',
    GET_BUILDING_CATEGORIES = 'getBuildingCategories',
    GET_BUILDING_MEASURES_FAQ = 'getBuildingMeasuresFaq',
    POST_BUILDING = 'postBuilding',
    POST_BUILDING_SCORE_STATUS = 'postBuildingScoreStatus',
    POST_PREPAYMENT_CHOICE = 'postPrepaymentChoice',
}

const BUILDING_INCLUDE = {
    renovationInterests: true,
    'currentRoboAdviceVersion.roboAdviceScores': true,
    'currentRoboAdviceVersion.recommendedMeasures': true,
    'serviceLineItems.bafaVollmachtDocuments': true,
    'serviceLineItems.areInitialRequiredUserDocumentsUploaded': true,
    'serviceLineItems.subsidiesService': true,
    'serviceLineItems.isBafaPowerOfAttorneyDocumentUploaded': true,
    roofShape: true,
    address: true,
    ownerUUID: true,
}

export const BUILDING_CALLS = {
    queries: {
        [BuildingCallsBasicKey.GET_ALL_BUILDINGS]: {
            getKey: () => [BuildingCallsBasicKey.GET_ALL_BUILDINGS],
            call: (enterApi: EnterApi) =>
                enterApi
                    .get<GetBuildings[]>(
                        createApiUri<BuildingsInclude>('/main/buildings', {
                            address: true,
                        })
                    )
                    .then(({ data }) => data),
        },

        [BuildingCallsBasicKey.GET_BUILDING]: {
            getKey: (buildingUuid: string) => [BuildingCallsBasicKey.GET_BUILDING, buildingUuid],
            call: (enterApi: EnterApi, args: GetBuildingArgs) =>
                enterApi
                    .get<GetBuildings>(
                        createApiUri<BuildingsInclude>(`/main/buildings/${args.buildingUuid}`, BUILDING_INCLUDE, {})
                    )
                    .then(({ data }) => data),
        },

        [BuildingCallsBasicKey.GET_BUILDING_MEASURES]: {
            getKey: (buildingUuid: string) => [BuildingCallsBasicKey.GET_BUILDING_MEASURES, buildingUuid],
            call: (enterApi: EnterApi, buildingUuid: string) =>
                enterApi
                    .get<GetBuildingMeasures>(createApiUri<null>(`/main/buildings/${buildingUuid}/measures`, {}))
                    .then(({ data }) => data),
        },

        [BuildingCallsBasicKey.GET_BUILDING_CATEGORIES]: {
            getKey: (buildingUuid: string, surveyName: `${SurveyName}`) => [
                BuildingCallsBasicKey.GET_BUILDING_CATEGORIES,
                buildingUuid,
                surveyName,
            ],
            call: (enterApi: EnterApi, buildingUuid: string, surveyName: `${SurveyName}`) => {
                return enterApi
                    .get<GetBuildingCategories>(
                        createApiUri<null>(
                            `/main/buildings/categories/${buildingUuid}`,
                            {},
                            { overviewCategory: surveyName }
                        )
                    )
                    .then(({ data }) => data)
            },
        },

        [BuildingCallsBasicKey.GET_BUILDING_MEASURES_FAQ]: {
            getKey: (buildingUuid: string, subsidizableMeasureComponent: BuildingMeasuresValues) => [
                BuildingCallsBasicKey.GET_BUILDING_MEASURES_FAQ,
                buildingUuid,
                subsidizableMeasureComponent,
            ],
            call: (enterApi: EnterApi, buildingUuid: string, subsidizableMeasureComponent: BuildingMeasuresValues) =>
                enterApi
                    .get<GetBuildingMeasuresFaq>(
                        createApiUri<null>(`/main/buildings/${buildingUuid}/faq/${subsidizableMeasureComponent}`, {})
                    )
                    .then(({ data }) => data),
        },
    },
    mutations: {
        [BuildingCallsBasicKey.POST_BUILDING]: {
            getKey: (buildingUuid: string) => [BuildingCallsBasicKey.POST_BUILDING, buildingUuid],
            call: (enterApi: EnterApi, payload: SubmitBuildingPayload, buildingUuid: string) => {
                return enterApi
                    .post<undefined, SubmitBuildingPayload>(`/main/buildings/${buildingUuid}`, {
                        data: payload,
                    })
                    .then((response) => response.data)
            },
        },
        [BuildingCallsBasicKey.POST_BUILDING_SCORE_STATUS]: {
            getKey: (buildingUuid: string) => [BuildingCallsBasicKey.POST_BUILDING, buildingUuid],
            call: (enterApi: EnterApi, payload: SubmitBuildingPayload, buildingUuid: string) => {
                return enterApi
                    .post<undefined, SubmitBuildingPayload>(`/main/buildings/${buildingUuid}/roboAdviceInfoStatus`, {
                        data: payload,
                    })
                    .then((response) => response.data)
            },
        },
        [BuildingCallsBasicKey.POST_PREPAYMENT_CHOICE]: {
            getKey: () => [],
            call: (enterApi: EnterApi, choice: boolean) => {
                return enterApi
                    .post<undefined, SubmitBuildingPayload>(`/user/profile/signup-for-discount?discount=${choice}`, {})
                    .then((response) => response.data)
            },
        },
    },
}
