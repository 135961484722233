import { useTheme } from '../../styles/theme'
import { LoadingSpinner } from '../loading/loadingSpinner'
import { getLoadingStyles } from './PageLoading.styles'

export const PageLoading = () => {
    const theme = useTheme()

    return (
        <LoadingSpinner
            height="50px"
            width="50px"
            color={theme.colors.secondary.standard}
            styles={getLoadingStyles()}
        />
    )
}

export default PageLoading
