import { useEffect, useState } from 'react'
import { Page } from '@utils/urls'
import { useAuthentication } from 'lib/components/authentication/hooks/useAuthentication'
import { useRouter } from 'next/router'
import { useGetAllBuildings } from '@api/buildings/buildingsHooks'
import { useMe } from '@api/auth/authHooks'
import { saveBuildingsInLocalStorage } from '@api/buildings/buildingsHelpers'
import PageLoading from 'lib/components/PageLoading/PageLoading'
import { datadogRum } from '@datadog/browser-rum'

const MAX_RETRY = 3
const BASE_TIMEOUT = 1000

export default function IndexPage() {
    useAuthentication()
    const router = useRouter()
    const {
        data: buildings,
        isLoading: isBuildingsLoading,
        isRefetching: isBuildingsRefetching,
        refetch,
    } = useGetAllBuildings({ enabled: true })

    const { data: me, isLoading: isMeLoading } = useMe(true)
    const [retryCount, setRetryCount] = useState<number>(0)

    useEffect(() => {
        if (isMeLoading) return

        if (!me) window.location.href = window.location.origin + Page.LOGIN

        if (isBuildingsLoading || isBuildingsRefetching) return

        const firstBuilding = buildings && buildings[0]
        const userUuid = me?.uuid || ''

        if (firstBuilding) {
            saveBuildingsInLocalStorage({
                userUuid,
                buildingUuid: firstBuilding.uuid,
            })

            void router.push(Page.DASHBOARD({ buildingUuid: firstBuilding.uuid }))
            return
        } else {
            console.log('Something went wrong with fetching a building for user')
            const error = new Error(`Something went wrong with fetching a building for user: ${userUuid}`)
            datadogRum.addError(error, { buildings })

            setTimeout(() => {
                if (retryCount <= MAX_RETRY) {
                    setRetryCount(retryCount + 1)
                    refetch()
                } else {
                    // TODO [UAQ-726] https://linear.app/enternow/issue/UAQ-726/error-page-for-lack-of-building
                    void router.push(Page.PROFILE)
                    return
                }
            }, BASE_TIMEOUT * retryCount)
        }
    }, [isBuildingsLoading, buildings, router, isMeLoading, me, refetch, isBuildingsRefetching, retryCount])

    return <PageLoading />
}
