import {
    IMPERSONATION_ADMIN_TOKEN,
    IMPERSONATION_ADMIN_TOKEN_EXPIRY,
    getImpersonationAdminToken,
    setImpersonationAdminToken,
    getUnexpiredToken,
} from 'lib/utils/authentication'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useTransferAdminToken = () => {
    const router = useRouter()

    useEffect(() => {
        const urlAdminTokenExpiry = router.query[IMPERSONATION_ADMIN_TOKEN_EXPIRY] as string
        const urlAdminToken = getUnexpiredToken(router.query[IMPERSONATION_ADMIN_TOKEN] as string, urlAdminTokenExpiry)

        if (urlAdminToken && typeof window !== 'undefined' && router.isReady) {
            const currentCookie = getImpersonationAdminToken()
            if (currentCookie !== urlAdminToken) {
                setImpersonationAdminToken(urlAdminToken, parseInt(urlAdminTokenExpiry))
            }
        }
    }, [router.isReady, router.query])
}
